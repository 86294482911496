.auto-1440 {
  padding: 0.8rem 0;
}

.title {
  text-align: center;
  font-size: 0.24rem;
  padding-bottom: 0.2rem;
  color: #333;
  margin-bottom: 0.2rem;
}

.title::before {
  content: '';
  width: 0.8rem;
  height: 0.06rem;
  border-radius: 0.03rem;
  background-color: #00ffc0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.form {
  padding: 0.4rem;
  background-color: #f5f5f5;
}

/deep/ .ant-form {
  max-width: 7rem;
  margin: 0 auto;
}

.form-item {
  display: flex;
  align-items: center;
}

.form-item .input {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.form-item .input :deep(.anticon-info-circle) {
  position: absolute;
  left: calc(100% + 0.15rem);
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

.form-item .label {
  width: 0.85rem;
  text-align: justify;
  text-align-last: justify;
  margin-right: 0.15rem;
  font-size: 0.16rem;
  font-weight: bold;
}

.form-item :deep(.ant-input) {
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.05rem;
}

.form-item :deep(.ant-input-disabled) {
  background-color: #eee;
}

.form-item .login_btn {
  min-width: 1.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  margin-left: 0.15rem;
  border-radius: 0.05rem;
  background-color: #00ffc0;
  border-color: #00ffc0;
  font-weight: bold;
  font-size: 0.16rem;
}

.form-item .login_btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
}

.form-item .login_btn:focus {
  color: #000;
}

:deep(.ant-form-explain) {
  padding-left: 0.9rem;
}

.submit {
  text-align: center;
  justify-content: center;
}

.submit .ant-btn {
  min-width: 1.4rem;
  margin-left: 0;
}